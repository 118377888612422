import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const AzureCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create Enterprise Application</Title>

    <Text>Select "Enterprise applications" from your Azure AD dashboard.</Text>

    <Img priority height={1280} src="/sso/azure/v1/azure-1.png" width={2234} />

    <Text>Click "New application" and continue.</Text>

    <Img priority height={1084} src="/sso/azure/v1/azure-2.png" width={2222} />

    <Text>
      Select "Create your own application", then enter an App name that
      describes {appName}. Under "What are you looking to do with your
      application?", select "Integrate any other application you don't find in
      the gallery (Non-gallery)", then select "Create".
    </Text>

    <Img height={524} src="/sso/azure/v1/azure-3.png" width={1148} />

    <Text>
      Next, select "Single Sign On" from the "Manage" section in the left
      sidebar navigation menu, and then "SAML".
    </Text>

    <Img height={1098} src="/sso/azure/v1/azure-4.png" width={2706} />

    <Confirm
      label="I’ve created a SAML Enterprise Application"
      onClick={onNextStep}
    />
  </Article>
);

export const AzureSamlConfiguration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Basic SAML Configuration</Title>

    <Text>Click the Edit icon in the top right of the first step.</Text>

    <Img priority height={366} src="/sso/azure/v1/azure-5.png" width={1528} />

    <CopyInput
      label="Copy this Identifier"
      value={connection?.saml_entity_id}
    />

    <CopyInput label="Copy this Reply URL" value={connection?.saml_acs_url} />

    <Text>
      Submit the Identifier and the Reply URL in the Basic SAML Configuration.
    </Text>

    <Img height={1026} src="/sso/azure/v1/azure-6.png" width={1690} />

    <Confirm
      label="I’ve completed my Basic SAML Configuration."
      onClick={onNextStep}
    />
  </Article>
);

export const AzureUserAttributes: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">Step 3: User Attributes & Claims</Title>

    <Text>Click the Edit icon in the top right of the second step.</Text>

    <Img priority height={366} src="/sso/azure/v1/azure-7.png" width={1528} />

    <Text>Fill in the following Attribute Statements and select "Next":</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img height={942} src="/sso/azure/v1/azure-8.png" width={1582} />

    <Confirm
      label="I’ve configured the User Attributes & Claims."
      onClick={onNextStep}
    />
  </Article>
);

export const AzureAssignPeople: React.FC<Readonly<ConnectionStepProps>> = ({
  appName,
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">Step 4: Assign People & Groups</Title>

    <Text>
      In order for your users and groups of users to be synced to {appName} you
      will need to assign them to your Azure AD SAML Application. Select "Users
      and groups" from the "Manage" section of the navigation menu.
    </Text>

    <Img priority height={770} src="/sso/azure/v1/azure-9.png" width={1228} />

    <Text>Select "Add user/group" from the top menu.</Text>

    <Img priority height={710} src="/sso/azure/v1/azure-10.png" width={1946} />

    <Text>
      Select "None selected" under the "Users and Groups". In the menu, select
      the users and groups of users that you want to add to the SAML
      application, and click "Select".
    </Text>

    <Img height={436} src="/sso/azure/v1/azure-11.png" width={3142} />

    <Text>
      Select "Assign" to add the selected users and groups of users to your SAML
      application.
    </Text>

    <Img height={1742} src="/sso/azure/v1/azure-12.png" width={1380} />

    <Confirm label="I’ve completed my assignments." onClick={onNextStep} />
  </Article>
);

export const AzureMetadataUpload: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://sts.windows.net/123-abc/"
    idpSsoUrlPlaceholder="https://login.microsoftonline.com/123abc/saml2"
    metadataUrlPlaceholder="https://login.microsoftonline.com/123-abc/..."
    stepTitle="Step 5: Upload IdP Metadata"
  >
    <Text>
      Navigate down to Section 3 of the "Single Sign On" page, to "SAML Signing
      Certificate". Copy the url provided in "App Federation Metadata URL".
    </Text>
    <Img
      priority
      height={275}
      src="/sso/azure/v1/azure-saml-metadata-upload.png"
      width={757}
    />
    <Text>
      Next, within your connection settings, edit the Metadata Configuration and
      provide the Metadata URL you obtained from the Azure Dashboard.
    </Text>
  </MetadataUrlStep>
);
