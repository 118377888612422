import { Text } from '@workos-inc/component-library';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const Auth0CreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create SAML Application</Title>

    <Text>
      In your Auth0 Dashboard, select Applications {'>'} Applications from the
      sidebar. Then select "Create Application".
    </Text>

    <Img priority height={556} src="/sso/auth0/v1/auth0-1.png" width={1475} />

    <Text>
      Enter an "Application Name" that describes {appName}, select the "Regular
      Web Applications" type, then click "Create".
    </Text>

    <Img priority height={1240} src="/sso/auth0/v1/auth0-2.png" width={1596} />

    <Text>
      Select the "Addons" tab in your new application, and then open the SAML2
      WEB APP menu.
    </Text>

    <Img height={412} src="/sso/auth0/v1/auth0-3.png" width={1030} />

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      Enter the ACS URL provided above in the "Application Callback URL" input
      box.
    </Text>

    <Img height={1328} src="/sso/auth0/v1/auth0-4.png" width={1274} />

    <Text>
      Scroll down to the bottom of the AddOn modal and select "Enable".
    </Text>

    <Img height={1784} src="/sso/auth0/v1/auth0-5.png" width={1276} />

    <Confirm
      label="I've configured the ACS URL in the SAML application."
      onClick={onNextStep}
    />
  </Article>
);

export const Auth0UploadMetadata: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
    idpSsoUrlPlaceholder="https://mycompany.us.auth0.com/samlp/AbC123..."
    metadataUrlPlaceholder="https://"
    stepTitle="Step 2: Upload Identity Provider Metadata"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      On the application's Settings page, scroll down to the bottom and expand
      the "Advanced Settings" section. Select the "Endpoints" tab and copy the
      SAML Metadata URL and enter it in the field below.
    </Text>

    <Img
      priority
      height={817}
      src="/sso/auth0/v1/auth0-metadata-url.png"
      width={846}
    />
  </MetadataUrlStep>
);
