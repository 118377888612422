import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataXmlStep } from 'components/sso/steps/saml/metadata-xml-step';
import { Article, Ol, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const OneLoginCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create a SAML Application</Title>

    <Text>
      Click “Add App” in the top right corner of your application dashboard.
    </Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/one-login-1.png"
      width={1278}
    />

    <Text>Click “SAML Custom Connector (Advanced)” and continue.</Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/one-login-15.png"
      width={1278}
    />

    <Text>
      Enter a Display Name that describes {appName}, then select “Save”.
    </Text>

    <Img height={740} src="/sso/one-login/v1/one-login-16.png" width={1278} />

    <Confirm
      label="I’ve created a SAML Enterprise Application"
      onClick={onNextStep}
    />
  </Article>
);

export const OneLoginBasicConfiguration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Basic SAML Configuration</Title>

    <Text>Click the “Configuration” tab from the left sidebar.</Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/one-login-4.png"
      width={1278}
    />

    <CopyInput
      label="Copy this ACS URL Validator"
      value={
        connection?.saml_acs_url
          ? `^${connection?.saml_acs_url.replace(/\//g, '\\/')}$`
          : undefined
      }
    />

    <Text>Submit the “ACS URL Validator” in the Application details.</Text>

    <Img height={740} src="/sso/one-login/v1/one-login-5.png" width={1278} />

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>Submit the “ACS URL” in the Application details.</Text>

    <Img height={740} src="/sso/one-login/v1/one-login-6.png" width={1278} />

    <Text>
      Enter the Login URL that your SP uses to initiate the login in the Login
      URL field.
    </Text>

    <Img height={740} src="/sso/one-login/v1/one-login-14.png" width={975} />

    <Confirm
      label="I’ve completed my Basic SAML Configuration."
      onClick={onNextStep}
    />
  </Article>
);

export const OneLoginAdvancedConfiguration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Advanced SAML Configuration</Title>

    <Text>
      Continue scrolling down the Application Details to “SAML Initiator”.
    </Text>

    <Text>Select “Service Provider” under “SAML Initiator”.</Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/one-login-7.png"
      width={1278}
    />

    <Text>Select “Assertion” under “SAML signature element”.</Text>

    <Img height={740} src="/sso/one-login/v1/one-login-8.png" width={1278} />

    <Confirm
      label="I’ve selected “Assertion” as the signature element."
      onClick={onNextStep}
    />
  </Article>
);

export const OneLoginConfigureParameters: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 4: Configure Parameters</Title>

    <Text>Click the “Parameters” tab from the left sidebar.</Text>

    <Img
      priority
      height={740}
      src="/sso/one-login/v1/one-login-9.png"
      width={1278}
    />

    <Text>
      {`Fill in the following parameters and check the "Include in SAML
            assertion" flag for each pair.`}
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img height={740} src="/sso/one-login/v1/one-login-10.png" width={1278} />

    <Confirm
      label="I’ve configured the Application’s parameters."
      onClick={onNextStep}
    />
  </Article>
);

export const OneLoginUploadMetadata: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://app.onelogin.com/saml/metadata/..."
    idpSsoUrlPlaceholder="https://foocorp.onelogin.com/trust/saml2/http-post/sso/..."
    stepTitle="Step 5: Download OneLogin Metadata File"
  >
    <Ol className="my-4">
      <li>Click the “SSO” tab from the left sidebar.</li>

      <li>
        Click the "More Actions" dropdown and then select "SAML Metadata".
      </li>

      <li>An XML file will download.</li>
    </Ol>

    <Img
      priority
      height={801}
      src="/sso/one-login/v1/one-login-17.png"
      width={1233}
    />

    <Text>Upload the XML Metadata file below.</Text>
  </MetadataXmlStep>
);
